import { EBase } from "@/assets/enums";
import Airtable from "airtable";

const apiKey = process.env.VUE_APP_AIRTABLE_API_KEY;
const baseKey = "apphO3OBQdCDOPw8B";
const _base = new Airtable({ apiKey }).base(baseKey);

export const Get = (base: EBase, manipulateRecord, view?: string) => {
  _base(base)
    .select({
      maxRecords: 10000,
      view: view ?? "Grid view",
    })
    .eachPage(
      function page(records, fetchNextPage) {
        records.forEach((record) => manipulateRecord(record));
        fetchNextPage();
      }
      //   function done(err) {}
    );
};
