<template>
  <div class="image_showcase_wrapper">
    <div
      v-bind:key="url"
      v-for="({ url }, index) in this.$store.getters.getHeaderImages()"
      class="image_showcase lazy"
    >
      <img
        :class="{
          fadeIn: index === imageIndex,
          fadeOut: index !== imageIndex,
          shown: isInitialImage && index === imageIndex,
        }"
        :style="{ zIndex: images?.length - index }"
        :src="url"
        class="image_showcase_single"
        loading="lazy"
      />
    </div>

    <div class="under_image_box color_brown_dark">
      <p>
        {{ this.$store.getters.getHeaderImages()?.[this.imageIndex]?.name }}
      </p>
      <div class="paginator_elements">
        <div
          v-bind:key="obj"
          v-for="(obj, index) in this.$store.getters.getHeaderImages()"
          class="paginator_wrapper"
          v-bind:style="{ opacity: index === imageIndex ? 1 : 0.4 }"
          v-on:click="goToImage(index)"
        >
          <div class="paginator_element" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
@Options({
  components: {},
  data() {
    return {
      imageIndex: 0,
      isInitialImage: true,
    };
  },
  mounted() {
    this.changeImage();
  },
  methods: {
    discardInitialImageState() {
      if (this.isInitialImage) {
        this.isInitialImage = false;
      }
    },
    goToImage(index) {
      if (index !== this.imageIndex) {
        this.discardInitialImageState();
        this.imageIndex = index;
      }
    },
    changeImage() {
      setInterval(() => {
        this.discardInitialImageState();
        const isLastImage =
          this.imageIndex === this.$store.getters.getHeaderImages()?.length - 1;
        this.goToImage(isLastImage ? 0 : this.imageIndex + 1);
      }, 5000);
    },
  },
})
export default class HeaderImages extends Vue {}
</script>

<style scoped>
.shown {
  opacity: 1;
  z-index: 2;
  animation: none !important;
}
.hidden {
  opacity: 0;
}
.fadeIn {
  animation: fadeInAnimation linear 1s forwards;
}
.fadeOut {
  animation: fadeOutAnimation linear 1s forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

.paginator_wrapper {
  padding: 5px;
  cursor: pointer;
}

.image_showcase_wrapper {
  width: 100%;
  position: relative;
}
.under_image_box {
  position: absolute;
  top: 720px;
  right: 20px;
}
.image_showcase {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  overflow: hidden;
}
.image_showcase_single {
  width: 100%;
  height: 680px;
  border-radius: 0px 0px 0px 50px;
  min-width: 600px;
  position: relative;
}

.paginator_elements {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.paginator_element {
  border-radius: 50%;
  width: 4px;
  height: 4px;
  background-color: black;
  cursor: pointer;
}
</style>
