<template>
  <div class="wrapper">
    <div class="sub_wrapper sub_wrapper_map">
      <div id="map"></div>
    </div>
  </div>
</template>

<script>
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { EObjekti } from "../assets/enums";

const LV_CENTER_COORDS = [24.8, 56.85];

export default {
  mounted() {
    mapboxgl.accessToken = process.env.VUE_APP_MAP_ACCESS_TOKEN;
    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/thebyar/cl6mdsd60004a14rupfh8xgo5",
      zoom: 6.6,
      center: LV_CENTER_COORDS,
    });

    map.on("load", () => {
      map.scrollZoom.disable();
      map.addControl(new mapboxgl.NavigationControl(), "bottom-left");

      for (const feature of this.$store.getters.getMapGeoJSON()) {
        // create a HTML element for each feature
        const el = document.createElement("div");
        el.className = "marker";

        const markerBg = document.createElement("div");
        markerBg.className = "marker_bg";
        el.appendChild(markerBg);

        const markerImg = document.createElement("div");
        markerImg.style.backgroundImage = `url(${
          feature.properties[EObjekti.IMG]
        })`;
        markerImg.className = "marker_img";

        markerBg.appendChild(markerImg);
        // make a marker for each feature and add to the map
        new mapboxgl.Marker(el, {
          // offset: [0, -50],
          anchor: "bottom",
        })
          .setLngLat(feature.geometry.coordinates)
          .setPopup(
            new mapboxgl.Popup({
              focusAfterOpen: false,
            }) // add popups
              .setHTML(
                `
                <h3 class="popup_header">${
                  feature.properties[EObjekti.NAME]
                }</h3>

                <img class="popup_img" loading="lazy" src="${
                  feature.properties[EObjekti.IMG]
                }"/>
                <span class="popup_tips bg_brown_dark color_brown">
                ${feature.properties[EObjekti.TYPE]}
                </span>
                <p class="popup_desc color_brown_dark">${
                  feature.properties[EObjekti.LONG_DESC]
                }</p>`
              )
          )
          .addTo(map);
      }
      // TODO: Here we want to load a layer
      // TODO: Here we want to load/setup the popup
    });
  },
};
</script>

<style>
.sub_wrapper_map {
  width: 100vw;
  margin: 0px;
  background-color: #00000010;
  padding: 16px 0px 16px 0px !important;
}

#map {
  width: 100%;
  height: 850px;
  max-height: 80vh;

  box-sizing: border-box;
}

.marker {
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 0px 0px 3px #36231a;
  background-color: #36231a;
  display: flex;
}

.marker_bg {
  background-image: url("./../assets/marker.png");
  position: relative;
  width: 54px;
  height: 60px;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  flex-direction: row;
  align-items: flex-start;
}

.marker_img {
  width: 50px;
  height: 50px;
  background-size: cover;
  border-radius: 50%;
}

.mapboxgl-popup-content,
.mapboxgl-popup-close-button {
  outline: none;
  background-color: #fdf2e8;
  box-sizing: border-box;
}

.mapboxgl-popup-close-button {
  top: 0.5rem;
  right: 0.5rem;
  font-size: 1.5rem;
  border-radius: 100%;
  position: fixed;
}

/* OPEN CARD */
.mapboxgl-popup-tip {
  border-bottom-color: #fdf2e8 !important;
  display: none;
}

.popup_header_box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px 10px 0px;
}

.mapboxgl-popup {
  width: 40%;
  max-width: 100% !important;
  transform: none !important;
  right: 0px;
  left: unset !important;
  height: 100%;
  box-shadow: 0 0 16px #00000040;
}
@media screen and (max-width: 1280px) {
  .mapboxgl-popup {
    width: 60%;
  }
}
@media screen and (max-width: 768px) {
  .mapboxgl-popup {
    width: 90%;
  }
}

.mapboxgl-popup-content {
  text-align: left;
  border-radius: 0px !important;
  height: 100%;
  padding: 2rem;
  font-size: 1rem;
  overflow: scroll;
}
</style>
