import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ebe0e0d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "b_item_container" }
const _hoisted_2 = { class: "b_item_desc_box" }
const _hoisted_3 = { class: "b_item_h color_brown_dark bg_brown" }
const _hoisted_4 = { class: "b_item_desc color_brown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.$store.getters.getBuvizstradajumi(), (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "b_item_box",
        key: item
      }, [
        _createElementVNode("div", {
          style: _normalizeStyle({ backgroundImage: `url(${item?.attels?.[0]})` }),
          class: "b_img"
        }, null, 4),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(item?.nosaukums), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(item?.issApraksts), 1)
        ])
      ]))
    }), 128))
  ]))
}