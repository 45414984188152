<template>
  <div class="wrapper footer bg_brown_dark color_brown_light">
    <span class="sub_wrapper footer_box">
      <div class="footer_section koka_centrs_info">
        <h4 class="color_brown">KOKA CENTRS</h4>
        <p>Latvijas koka būvniecības un arhitektūras centrs</p>
        <p class="skinny_text">2022 © Koka Centrs</p>
      </div>
      <div class="footer_section">
        <h4 class="color_brown">Lapas saturs</h4>
        <div class="footer_navigations">
          <div
            v-bind:key="nosaukums"
            v-for="{ nosaukums } in this.$store.getters.getPageStructure()"
          >
            <p
              class="section_name underline pointer"
              v-on:click="onSectionClick(nosaukums)"
            >
              {{ nosaukums }}
            </p>
          </div>
        </div>
      </div>
      <div class="footer_section social_section">
        <h4 class="color_brown">Sociālie tīkli</h4>
        <a class="social underline">Facebook</a>
        <a class="social underline">Instagram</a>
      </div>
    </span>

    <div class="divider" />
    <div class="sub_wrapper">
      <img
        src="./../assets/eraf_logo_white.png"
        class="eraf_logo"
        loading="lazy"
      />
      <p class="skinny_text">
        Projekts Nr. 3.2.1.1/16/A/013 “Koka būvniecības klastera starptautiskas
        konkurētspējas veicināšana”
      </p>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import ERAF from "./ERAF.vue";
import scrollToId from "./../assets/utils";

@Options({
  components: {
    ERAF,
  },
  props: {
    // msg: String,
  },
  data() {
    return {};
  },
  methods: {
    onSectionClick(section) {
      const elementId = document.getElementById(`${section}`);
      elementId.scrollIntoView({ behavior: "smooth" });
    },
  },
})
export default class Header extends Vue {
  // msg!: string;
}
</script>

<style scoped>
.divider {
  border-top: 1px solid #fdf2e820;
}

.skinny_text {
  font-weight: 500px;
  line-height: 1.4;
  font-size: 0.875rem;
  opacity: 0.6;
}
.footer_navigations {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.koka_centrs_info {
}
.footer_box {
  display: inline-grid;
  grid-template-rows: auto;
  grid-template-columns: 2fr 2fr 1fr;
  gap: 2rem;
  justify-items: stretch;
  align-items: start;
  box-sizing: border-box;
}

.footer_section {
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: left;
  flex-wrap: wrap;
}

.footer {
}

.eraf_logo {
  width: 420px;
  margin-bottom: 2rem;
  max-width: 90vw;
}
.social_section {
}
.section_name,
.social {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
  opacity: 0.6;
}
.section_name:hover,
.social:hover {
  text-decoration: none;
}
@media screen and (max-width: 1024px) {
  .footer_box {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 640px) {
  .footer_box {
    grid-template-columns: 1fr;
  }
}
</style>
