<template>
  <div class="wrapper bg_brown color_brown_dark" :id="id">
    <div class="sub_wrapper objekti_container">
      <h2>{{ nosaukums }}</h2>
      <p class="desc">{{ apraksts }}</p>
    </div>

    <div class="filter_row" v-if="this.$store.getters.getObjektuTipi()?.length">
      <span
        class="filter_tag bg_brown_dark color_brown"
        v-for="type in this.$store.getters.getObjektuTipi()"
        v-bind:key="type"
        :class="{
          unselected: !this.$store.getters.getVisibleObjekti().includes(type),
        }"
        v-on:click="() => toggleType(type)"
      >
        {{ type }}
      </span>
    </div>

    <ObjektiList />
    <Map />
    <Contacts class="contacts" :text="'Plāno būvēt no koka?'" />
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import ObjektiList from "./ObjektiList.vue";
import Map from "./ObjektiMap.vue";
import Contacts from "./ContactUs.vue";

@Options({
  components: { ObjektiList, Map, Contacts },
  props: {},
  data() {
    return {
      id: this.$store.getters.getObjektiInfo()?.nosaukums,
      nosaukums: this.$store.getters.getObjektiInfo()?.nosaukums,
      apraksts: this.$store.getters.getObjektiInfo()?.garaisApraksts,
    };
  },
  methods: {
    toggleType(type) {
      this.$store.commit("toggleObjektuType", type);
    },
  },
})
export default class Objekti extends Vue {}
</script>

<style scoped>
.objekti_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 0 !important;
}

.contacts {
  margin-bottom: 6rem;
}
@media screen and (max-width: 1024px) {
  .contacts {
    margin-bottom: 4rem;
  }
  .objekti_container {
    padding-bottom: 0 !important;
  }
}
</style>
