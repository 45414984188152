<template>
  <div class="background">
    <div class="bg_brown_light color_brown_dark">
      <img
        class="close_icon"
        v-on:click="close()"
        src="./../assets/close.png"
      />

      <h3 class="popup_header">
        {{ this.$store.getters.getSelectedItem().nosaukums }}
      </h3>

      <div class="content_wrapper">
        <img
          class="popup_img"
          loading="lazy"
          :src="this.$store.getters.getSelectedItem().attels?.[0]"
        />
        <div class="content_text_wrapper">
          <span class="popup_tips bg_brown_dark color_brown">
            {{ this.$store.getters.getSelectedItem().tips }}
          </span>
          <p class="popup_desc color_brown_dark">
            {{ this.$store.getters.getSelectedItem().garaisApraksts }}
          </p>

          <div class="links">
            <a
              class="color_brown_dark open_in_maps"
              target="_blank"
              :href="`https://www.google.com/maps/search/${
                this.$store.getters.getSelectedItem().koordinates[1]
              },${this.$store.getters.getSelectedItem().koordinates[0]}`"
              >Google maps</a
            >
            <a
              target="_blank"
              class="color_brown_dark objekts_url"
              :href="this.$store.getters.getSelectedItem().saite"
              >{{ parseLabel(this.$store.getters.getSelectedItem().saite) }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  methods: {
    close() {
      this.$store.commit("selectItem", null);
    },
    parseLabel(fullUrl) {
      const isFullUrl = fullUrl.includes("http");
      const cutIndex =
        fullUrl.split("/", isFullUrl ? 3 : 1).join("/").length + 1;
      const cutUrl = fullUrl.slice(0, cutIndex);
      return cutUrl;
    },
  },
  beforeMount() {},
  mounted() {},
})
export default class Dialog extends Vue {}
</script>

<style scoped>
.background {
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.background > div {
  width: 800px;
  max-width: 90vw;
  height: auto;
  min-height: 300px;
  max-height: 90vh;
  display: flex;
  border-radius: 24px;
  box-sizing: border-box;
  padding: 20px 20px 20px 20px;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
}

.content_wrapper {
  display: flex;
  gap: 20px;
  width: 100%;
  overflow: auto;
}

.content_text_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
}

.close_icon {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}
img {
  width: 300px;
  height: auto;
  max-height: 100%;
}

p {
  text-align: left;
}

.content_text_wrapper {
  margin-bottom: 30px;
}

h3 {
  max-width: 80%;
  text-align: left;
}

.links {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  position: absolute;
  bottom: 20px;
}

@media screen and (max-width: 640px) {
  .content_wrapper {
    flex-direction: column;
  }

  .popup_img {
    width: 100% !important;
    height: auto !important;
    max-height: 300px !important;
  }

  .popup_header {
    font-size: 1rem;
  }
}
</style>
