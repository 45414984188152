<template>
  <div class="contact_us_container">
    <p>{{ text }}</p>
    <p>
      Sazinies ar mums
      <a target="_blank" class="contact_us_a" :href="`mailto:${email}`">
        {{ email }}
      </a>
      <!-- or -->
      <!-- <a target="_blank" class="contact_us_a" :href="`tel:${tel}`">{{ tel }}</a> -->
    </p>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    text: String,
  },
  data() {
    return {
      tel: "+371 25 908 122",
      email: "kristaps@woodhouses.lv",
    };
  },
  methods: {},
})
export default class ContactUs extends Vue {}
</script>

<style scoped>
.contact_us_container {
  padding: 6rem 1.5rem 0 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.8;
}

.contact_us_a,
.contact_us_container {
  color: inherit;
}
@media screen and (max-width: 640px) {
  .contact_us_container {
    padding-top: 4rem;
  }
}
</style>
