
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
})
export default class Header extends Vue {}
