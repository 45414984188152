<template>
  <div class="wrapper apmacibas_wrapper bg_brown_light color_brown" :id="id">
    <div class="sub_wrapper apmacibas_container color_brown_dark">
      <h2>{{ nosaukums }}</h2>
      <p class="desc">{{ apraksts }}</p>
      <div
        class="filter_row"
        v-if="this.$store.getters.getApmacibuTipi()?.length"
      >
        <span
          class="filter_tag bg_brown_dark color_brown"
          v-for="type in this.$store.getters.getApmacibuTipi()"
          v-bind:key="type"
          :class="{
            unselected: !this.$store.getters
              .getVisibleApmacibuTipi()
              .includes(type),
          }"
          v-on:click="() => toggleType(type)"
        >
          {{ type }}
        </span>
      </div>
      <ApmacibasItem class="apmacibas_item" />
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import ApmacibasItem from "./ApmacibasItem.vue";

@Options({
  components: {
    ApmacibasItem,
  },
  props: {},
  data() {
    return {
      id: this.$store.getters.getApmacibasInfo()?.nosaukums,
      nosaukums: this.$store.getters.getApmacibasInfo()?.nosaukums,
      apraksts: this.$store.getters.getApmacibasInfo()?.garaisApraksts,
    };
  },

  methods: {
    toggleType(type) {
      this.$store.commit("toggleApmacibuType", type);
    },
  },
})
export default class Apmacibas extends Vue {}
</script>

<style scoped>
.apmacibas_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.apmacibas_item {
  width: 100%;
}
</style>
