<template>
  <div class="wrapper bg_brown_dark color_brown" :id="id">
    <div class="sub_wrapper color_brown buvizstradajumi_container">
      <h2>{{ nosaukums }}</h2>
      <p class="desc">{{ apraksts }}</p>
      <Item />
      <Contacts :text="'Vēlies uzzināt vairāk par būvizstrādājumiem?'" />
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { EBase } from "../assets/enums";
import Item from "./BuvizstradajumiItem.vue";
import Contacts from "./ContactUs.vue";

@Options({
  components: {
    Item,
    Contacts,
  },
  data() {
    return {
      id: this.$store.getters.getBuvizstradajumiInfo()?.nosaukums,
      nosaukums: this.$store.getters.getBuvizstradajumiInfo()?.nosaukums,
      apraksts: this.$store.getters.getBuvizstradajumiInfo()?.garaisApraksts,
    };
  },
  methods: {},
})
export default class Header extends Vue {
  // msg!: string;
}
</script>

<style scoped>
.wrapper {
  border-top: 1px solid #fdf2e820;
}
.buvizstradajumi_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
