import { EObjekti, ESectionComponents } from "./enums";

export const collectImages = (images) => images?.map(({ url }) => url);

export const findSection = (sections: any[], name: ESectionComponents) =>
  sections?.find(({ sadala }) => sadala === name);

export const shelfGeoJSON = (obj) => ({
  type: "Feature",
  properties: {
    [EObjekti.NAME]: obj?.[EObjekti.NAME] ?? "",
    [EObjekti.LONG_DESC]: obj?.[EObjekti.LONG_DESC] ?? "",
    [EObjekti.IMG]: obj?.[EObjekti.IMG]?.[0] ?? "",
    [EObjekti.TYPE]: obj?.[EObjekti.TYPE] ?? "",
  },
  geometry: {
    type: "Point",
    coordinates: obj[EObjekti.COORDS],
  },
});
