<template>
  <div class="wrapper bg_brown_light color_brown" :id="id">
    <div class="sub_wrapper color_brown_dark">
      <div class="notikumi_container">
        <h2>{{ nosaukums }}</h2>
        <p class="desc">{{ apraksts }}</p>
        <Item />
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { EBase } from "../assets/enums";
import Item from "./NotikumiItem.vue";

@Options({
  components: {
    Item,
  },
  props: {},
  data() {
    return {
      id: this.$store.getters.getNotikumiInfo()?.nosaukums,
      nosaukums: this.$store.getters.getNotikumiInfo()?.nosaukums,
      apraksts: this.$store.getters.getNotikumiInfo()?.garaisApraksts,
    };
  },
  methods: {},
})
export default class Notikumi extends Vue {}
</script>

<style scoped>
.sub_wrapper {
  padding-top: 0px;
  padding-bottom: 0px;
}
.notikumi_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #00000010;
  border-radius: 1.5rem 1.5rem 0px 0px;
  box-sizing: border-box;
  padding: 6rem;
}
@media screen and (max-width: 1280px) {
  .notikumi_container {
    padding: 4rem;
  }
  .sub_wrapper {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
@media screen and (max-width: 640px) {
  .notikumi_container {
    padding: 4rem 0.5rem;
  }
}
</style>
