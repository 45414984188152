<template>
  <div class="wrapper bg_brown_dark" :id="id">
    <div class="sub_wrapper color_brown">
      <h2>{{ nosaukums }}</h2>
      <p class="desc">{{ apraksts }}</p>
      <div class="image_item_section">
        <div
          class="img"
          v-bind:style="{
            backgroundImage: `url(${this.$store.getters.getKapecKoksImg()})`,
          }"
        />
        <KapecKoksItem />
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { EBase } from "../assets/enums";
import KapecKoksItem from "./KapecKoksItem.vue";
@Options({
  components: {
    KapecKoksItem,
  },
  props: {},
  data() {
    return {
      id: this.$store.getters.getKapecKoksInfo()?.nosaukums,
      nosaukums: this.$store.getters.getKapecKoksInfo()?.nosaukums,
      apraksts: this.$store.getters.getKapecKoksInfo()?.garaisApraksts,
    };
  },
  methods: {},
})
export default class KapecKoks extends Vue {}
</script>

<style scoped>
.image_item_section {
  display: flex;
  align-items: flex-start;
}

.img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  max-width: 600px;
  height: 480px;
  border-radius: 1.5rem;
  margin-right: 4rem;
}
</style>
