<template>
  <div class="item_container">
    <div
      v-bind:key="item?.name"
      v-for="item in this.$store.getters.getKapecKoksItems()"
      class="item"
    >
      <p class="item_header color_brown">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM7.76432 10.7465L12 6.05641L10.8646 5L7.13226 9.13277L5.00238 7.27976L4 8.46675L6.69543 10.8118C7.01078 11.0862 7.48367 11.0573 7.76432 10.7465Z" fill="#608652"/>
        </svg>

        {{ item.nosaukums }}
      </p>
      <p class="item_desc">{{ item.garaisApraksts }}</p>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
@Options({
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
})
export default class KapecKoksItem extends Vue {}
</script>

<style scoped>
.item_container {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.item_header {
  font-size: 18px;
  font-weight: 700;
  display: flex;
  gap: 10px;
  align-items: center;
}
.icon {
  min-width: 16px;
  height: 16px;
}

.item {
  text-align: left;
}

.item_desc {
  margin-top: 0.5rem;
  color: white;
  font-size: 1rem;
  opacity: 0.6;
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  .item_container {
    grid-template-columns: 1fr;
  }

}

</style>
