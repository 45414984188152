<template>
  <div class="wrapper bg_brown" :id="id">
    <div class="sub_wrapper header">
      <div class="header_left_side color_brown_dark">
        <h1 class="header_title">{{ nosaukums }}</h1>
        <p class="header_desc">{{ apraksts }}</p>
        <HeaderSections />
        <div class="divider" />
        <ERAF />
      </div>
      <div class="header_right_side">
        <HeaderImages />
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import HeaderImages from "./HeaderImages.vue";
import HeaderSections from "./HeaderSections.vue";
import ERAF from "./ERAF.vue";
import { EBase, EStruktura } from "./../assets/enums";

@Options({
  components: {
    HeaderImages,
    HeaderSections,
    ERAF,
  },
  props: {
    // msg: String,
  },
  data() {
    return {
      id: this.$store.getters.getHeaderInfo()?.nosaukums,
      EStruktura,
      nosaukums: this.$store.getters.getHeaderInfo()?.nosaukums,
      apraksts: this.$store.getters.getHeaderInfo()?.garaisApraksts,
    };
  },
  methods: {},
})
export default class Header extends Vue {
  // msg!: string;
}
</script>

<style scoped>
.sub_wrapper {
  padding: 0;
}
.header {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  min-height: 800px;
}
.header_title {
  text-transform: uppercase;
}
.header_desc {
  opacity: 0.8;
  font-size: 1.125rem;
}
.header_left_side {
  padding: 6rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 100%;
  box-sizing: border-box;
}

.header_right_side {
  height: 100%;
  justify-content: flex-start;
  padding-bottom: 4rem;
}
.section-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.section {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}

.divider {
  border-bottom: 1px solid #36231a20;
  margin: 1rem 0;
}

@media screen and (max-width: 1280px) {
  .header_left_side {
    padding: 4rem;
  }
}

@media screen and (max-width: 1024px) {
   .sub_wrapper {
     padding: 0 !important;
   }
}
@media screen and (max-width: 768px) {
  .header {
    height: unset;
    min-height: auto;
    grid-template-columns: 1fr;
  }
  .header_left_side {
    width: 100%;
    padding: 4rem;
  }
  .header_right_side {
    display: none !important;
  }
}
@media screen and (max-width: 640px) {
  .sub_wrapper {
    padding: 4rem 1.5rem !important;
  }

  .header_left_side {
    padding: 0px !important;
  }
}
</style>
