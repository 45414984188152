<template>
  <div class="objekti_wrapper">
    <div class="sub_wrapper objekti_list_container">
      <div
        v-bind:key="index"
        class="objekts_box"
        :style="{
          gridRowEnd: getCardSize(
            !!objekts.nosaukums && showMoreObjects ? true : index < 9,
            index
          ),
        }"
        v-for="(objekts, index) in this.$store.getters.getObjektiWithImages()"
      >
        <div v-if="showMoreObjects ? true : index < 9">
          <div
            class="objekts_img"
            v-on:click="selectObject(objekts)"
            :style="{
              backgroundImage: `url(${objekts?.attels?.[0]})`,
            }"
          />
          <div class="objekts_desc_box">
            <span class="objekts_tips bg_brown_dark color_brown">
              {{ objekts.tips }}
            </span>
            <h3
              class="objekts_name color_brown_dark"
              v-on:click="selectObject(objekts)"
            >
              {{ objekts.nosaukums }}
            </h3>
            <p class="color_brown_dark objekts_desc">
              {{ objekts.issApraksts }}
            </p>
            <p style="margin-bottom: 0.5rem">
              <a
                class="color_brown_dark open_in_maps"
                target="_blank"
                :href="`https://www.google.com/maps/search/${objekts.koordinates[1]},${objekts.koordinates[0]}`"
                >Google maps</a
              >
            </p>
            <p>
              <a
                target="_blank"
                class="color_brown_dark objekts_url"
                :href="objekts.saite"
                >{{ parseLabel(objekts?.saite) }}</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="this.$store.getters.getObjektiWithImages()?.length > 9"
      class="show_more color_brown_dark"
      v-on:click="toggleShowMore()"
    >
      <br />
      <br />
      <br />
      {{ showMoreObjects ? "Rādīt mazāk" : "Rādīt vairāk" }}
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
@Options({
  components: {},
  props: {},
  data() {
    return {
      showMoreObjects: false,
    };
  },
  methods: {
    getCardSize(show, index) {
      if (!show) {
        return 0;
      }

      const randomSize = Math.floor(Math.random() * 10);
      const size = randomSize * 2 + 60;
      return `span ${size}`;
    },
    selectObject(object) {
      this.$store.commit("selectItem", object);
    },
    toggleShowMore() {
      this.showMoreObjects = !this.showMoreObjects;
    },
    parseLabel(fullUrl) {
      if (!fullUrl) {
        return "";
      }

      const isFullUrl = fullUrl?.includes("http");
      const cutIndex =
        fullUrl.split("/", isFullUrl ? 3 : 1).join("/").length + 1;
      const cutUrl = fullUrl.slice(0, cutIndex);
      return cutUrl;
    },
  },
})
export default class Objekti extends Vue {}
</script>

<style scoped>
.objekti_list_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 10px;
  justify-content: center;
}
.objekti_list_container > div {
  position: relative;
}
.sub_wrapper {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.objekts_box {
  position: relative;
  height: 100%;
  padding: 0;
  margin: 20px 10px;
  box-sizing: border-box;
}

.objekts_box > div {
  border-radius: 24px;
  height: inherit;
  display: flex;
  flex-direction: column;
}

.objekts_box > div > span {
  height: -webkit-fill-available;
}

.objekts_img {
  border-radius: 24px;
  width: 100%;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  flex: 5;
  height: 100%;
  height: -webkit-fill-available;
}

.objekts_desc_box {
  padding: 1rem;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  flex: 1;
}

.objekts_name {
  margin: 0;
  cursor: pointer;
}

.objekts_tips {
  border-radius: 0.8rem;
  padding: 0.2rem 0.6rem;
  font-weight: 500;
  font-size: 0.875rem;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.objekts_desc {
  text-align: left;
  line-height: 1.4;
  opacity: 0.8;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.open_in_maps {
  text-decoration: underline;
  width: fit-content;
  text-align: left;
  cursor: pointer;
  line-height: 1.4;
  opacity: 0.8;
  font-weight: 500;
}

.objekts_url {
  text-align: left;
  line-height: 1.4;
  opacity: 0.8;
  font-weight: 500;
  word-break: break-all;
}
.open_in_maps:hover {
  text-decoration: none;
}

.show_more {
  margin-top: 4rem;
  text-decoration: underline;
  cursor: pointer;
}

.objekti_wrapper {
  margin: 0 0 1rem 0;
}
@media screen and (max-width: 1024px) {
  .objekti_list_container {
    grid-template-columns: repeat(auto-fill, 250px);
  }
  .sub_wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 640px) {
  .objekti_list_container {
    grid-template-columns: 1fr 1fr;
  }
  .sub_wrapper {
    padding: 0 0.5rem !important;
  }
  .objekts_name {
    font-size: 16px;
  }
  .objekts_desc,
  .open_in_maps,
  .objekts_url {
    font-size: 14px;
  }
  .objekts_desc_box {
    padding: 0.5rem;
  }

  .objekts_tips {
    font-size: 10px;
  }
}
</style>
