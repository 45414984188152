<template>
  <div>
    <router-view />
  </div>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Rubik:wght@700&display=swap");
body,
html {
  scroll-behavior: smooth;
  background-color: #fdf2e8;
  overflow-x: hidden;
}

#app {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #dab48f;
  height: 100vh;
  max-height: 100vh;
}

img {
  object-fit: cover;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  font-family: "Rubik", sans-serif;
  line-height: 1.2;
  font-weight: 700;
}
p {
  font-family: "Inter", sans-serif;
  line-height: 1.4;
  font-weight: 500;
}
p,
label,
a,
h1,
h2,
h3 {
  color: inherit;
}
h1 {
  font-size: 80px;
  margin: 0px;
}
h4 {
  font-size: 20px;
}
label,
p,
h4 {
  margin: 0;
}

h2 {
  font-weight: bold;
  font-size: 64px;
  margin: 0;
}
.underline {
  text-decoration: underline;
}
a {
  text-decoration: underline;
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}
body {
  margin: 0px;
}
.pointer {
  cursor: pointer;
}
.bg_brown_dark {
  background-color: #36231a;
}
.color_brown_dark {
  color: #36231a;
}
.bg_brown {
  background-color: #dab48f;
}
.color_brown {
  color: #dab48f;
}
.bg_brown_light {
  background-color: #fdf2e8;
}
.color_brown_light {
  color: #fdf2e8;
}
.bg_gray {
  background-color: #cbcbcb;
}
.color_white {
  color: white;
}

.wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
.popup_desc {
  margin-top: 1rem;
  overflow-y: auto;
  line-height: 1.5;
}

.popup_img {
  width: 100%;
  max-height: 250px;
  border-radius: 1rem;
  margin-top: 1rem;
}

.popup_header {
  color: #423026;
  font-size: 1.5rem;
  margin: 0;
}

.popup_tips {
  border-radius: 0.8rem;
  padding: 0.2rem 0.6rem;
  font-weight: 500;
  font-size: 0.875rem;
  display: inline-block;
  margin-top: 1rem;
}

.sub_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
  width: 1440px;
  padding: 6rem 8rem;
  box-sizing: border-box;
}

.desc {
  max-width: 32rem;
  font-size: 1.125rem;
  font-weight: 500;
  opacity: 0.8;
  margin-top: 1.5rem;
  margin-bottom: 6rem;
}

.unselected {
  opacity: 0.6;
}

.filter_row {
  margin-bottom: 6rem;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
}

@media screen and (min-width: 1500px) {
  .image_showcase_single {
    border-radius: 0px 0px 50px 50px !important;
  }
}

@media screen and (max-width: 1280px) {
  .sub_wrapper {
    padding: 4rem 4rem !important;
  }
}

@media screen and (max-width: 1024px) {
  h1 {
    font-size: 70px;
  }
  h2 {
    font-size: 60px;
  }

  .desc {
    font-size: 1rem;
  }

  .img {
    display: none !important;
  }

  .item_container {
    justify-content: center !important;
  }
  .b_item_container {
    justify-content: center !important;
  }
  .b_img {
    width: 60px !important;
    height: 60px !important;
    border-radius: 1rem !important;
  }

  .apmacibas_icon_column {
    display: none !important;
  }
}

@media screen and (max-width: 640px) {
  h1 {
    font-size: 4rem !important;
  }
  h2 {
    font-size: 2rem;
  }
  .desc {
    font-size: 16px;
    margin: 0 1rem;
  }
  .sub_wrapper {
    padding: 4rem 1.5rem !important;
  }

  .under_image_box {
    display: none !important;
  }
  .footer_navigations {
    max-width: 100% !important;
  }
  .footer_box {
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
  }
  .apmacibas_item_card {
    flex-direction: column !important;
    gap: 20px !important;
    align-items: center !important;
  }

  .partneri_box {
    gap: 40px !important;
  }
  .parneri_icon {
    width: 100px !important;
    height: 90px !important;
  }
  .popup_img {
    max-height: 150px !important;
  }
  .image_showcase_single {
    height: 530px !important;
  }
  .desc {
    margin-top: 1.5rem !important;
    margin-bottom: 3rem !important;
  }
}

@media screen and (max-width: 435px) {
  .item {
    width: unset !important;
  }
  .notikumi_item_container {
    padding: 0px 10px !important;
  }
  .notikumi_item {
    flex-direction: column !important;
    align-items: flex-start !important;
    text-align: left !important;
  }

  .footer_section {
    width: 100% !important;
  }

  .filter_tag {
    font-size: 0.775rem !important;
  }

  .apamcibas_item_desc_box {
    flex-wrap: wrap !important;
  }

  .objekti_container {
    padding: 32px 0px 0px 0px !important;
  }
}

/* SCROLLBAR */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: unset !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: unset !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 3px #36231a;
  -webkit-box-shadow: inset 0 0 3px #36231a;
  background-color: #36231a;
}

/* LOADER */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  align-content: center;
}
.loader {
  border: 4px solid #d9b48e;
  border-radius: 50%;
  border-top: 4px solid #36231a;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 3s linear infinite; /* Safari */
  animation: spin 3s linear infinite;
  animation-timing-function: linear;
}
.filter_tag {
  border-radius: 0.8rem;
  padding: 0.2rem 0.6rem;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
