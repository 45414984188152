<template>
  <div class="wrapper bg_brown" :id="id">
    <div class="sub_wrapper partneri_container color_brown_dark">
      <h2>{{ nosaukums }}</h2>
      <p class="desc">{{ apraksts }}</p>

      <div class="partneri_box">
        <div
          v-for="(partneris, index) in this.$store.getters.getPartneri()"
          v-bind:key="index"
          :style="{
            backgroundImage: `url(${partneris.attels})`,
          }"
          class="parneri_icon bg_brown_light"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { EBase } from "../assets/enums";

@Options({
  components: {},
  props: {},
  data() {
    return {
      id: this.$store.getters.getPartneriInfo()?.nosaukums,
      nosaukums: this.$store.getters.getPartneriInfo()?.nosaukums,
      apraksts: this.$store.getters.getPartneriInfo()?.garaisApraksts,
    };
  },
  methods: {},
})
export default class Partneri extends Vue {}
</script>

<style scoped>
.wrapper {
  border-top: 1px solid #36231a20;
}
.partneri_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partneri_box {
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap: 4rem;
  max-width: 1200px;
  flex-wrap: wrap;
}

.parneri_icon {
  background-color: white;
  width: 160px;
  height: 120px;
  border-radius: 1.5rem;
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center;
}
</style>
