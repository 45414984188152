<template>
  <div class="section_row color_brown_dark">
    <div
      v-bind:key="nosaukums"
      v-for="{ nosaukums } in this.$store.getters
        .getPageStructure()
        .filter(({ nosaukums }) => nosaukums != 'Koka Centrs')"
    >
      <p class="section_name" v-on:click="onSectionClick(nosaukums)">
        {{ nosaukums }}
      </p>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
@Options({
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {
    onSectionClick(section) {
      const elementId = document.getElementById(`${section}`);
      elementId.scrollIntoView({ behavior: "smooth" });
    },
  },
})
export default class HeaderSections extends Vue {}
</script>

<style scoped>
.section_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
}

.section_name {
  text-decoration: underline;
  font-size: 1rem;
  cursor: pointer;
  margin-right: 1.5rem;
  opacity: 0.8;

}
.section_name:hover {
  text-decoration: none;
  opacity: 1;
}
</style>
