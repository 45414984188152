<template>
  <div class="eraf_container">
    <img src="./../assets/eraf_logo.png" class="eraf_logo" loading="lazy" />
    <p class="eraf_desc">Projekts Nr. 3.2.1.1/16/A/013 “Koka būvniecības klastera starptautiskas konkurētspējas veicināšana”</p>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
@Options({
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
})
export default class ERAF extends Vue {}
</script>

<style scoped>
.eraf_container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  opacity: 0.8;

}

.eraf_logo {
  width: 250px;
  max-width: 95%;
}

.eraf_desc {
  font-weight: 500px;
  line-height: 1.4;
  font-size: 0.875rem;
}
</style>
