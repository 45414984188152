import {
  EApmacibas,
  EBase,
  EBuvizstradajumi,
  EKapecKoks,
  EKlasteris,
  EMediji,
  ENotikumi,
  EObjekti,
  EPartneri,
  ESectionComponents,
  EStruktura,
} from "@/assets/enums";
import { collectImages, findSection, shelfGeoJSON } from "@/assets/utils";
import { Get } from "@/utils/requests";
import { createStore } from "vuex";

export default createStore({
  state: {
    structure: [],
    kapecKoks: [],
    buvizstradajumi: [],
    objekti: [],
    objektuTipi: [],
    visibleObjektuTipi: [],
    apmacibas: [],
    apmacibuTipi: [],
    visibleApmacibuTipi: [],
    mediji: [],
    medijiTipi: [],
    visibleMedijiTipi: [],
    notikumi: [],
    klasteri: [],
    klasterisTipi: [],
    visibleKlasterisTipi: [],
    partneri: [],
    selectedItem: null,
  },
  // Filter, read data from state
  getters: {
    getSelectedItem: (state) => () => {
      return state.selectedItem;
    },
    // HEADER GETTERS
    getPageStructure: (state) => () => {
      return state.structure;
    },
    getHeaderInfo: (state) => () => {
      return findSection(state.structure, ESectionComponents.KOKA_CENTRS);
    },
    getHeaderImages: (state) => () => {
      const images = state.objekti
        ?.filter((object) => object?.[EObjekti.SHOW_IN_LANDING_PAGE])
        ?.map((object) => ({
          name: object?.[EObjekti.NAME],
          url: object?.[EObjekti.IMG]?.[0],
        }));
      return images;
    },
    // KAPEC KOKS GETTERS
    getKapecKoksInfo: (state) => () => {
      return findSection(state.structure, ESectionComponents.KAPEC_KOKS);
    },
    getKapecKoksImg: (state) => () => {
      return state.kapecKoks.find(({ attels }) => attels)?.attels?.[0];
    },
    getKapecKoksItems: (state) => () => {
      return state.kapecKoks;
    },
    // BUVIZSTRADAJUMI GETTERS
    getBuvizstradajumiInfo: (state) => () => {
      return findSection(state.structure, ESectionComponents.IZSTRADAJUMI);
    },
    getBuvizstradajumi: (state) => () => {
      return state.buvizstradajumi;
    },
    // OBJEKLTI GETTERS
    getObjektiInfo: (state) => () => {
      return findSection(state.structure, ESectionComponents.OBJEKTI);
    },
    getObjekti: (state) => () => {
      return state.objekti;
    },
    getObjektuTipi: (state) => () => {
      return state.objektuTipi;
    },
    getVisibleObjekti: (state) => () => {
      return state.visibleObjektuTipi;
    },
    getObjektiWithImages: (state) => () => {
      const objektuList = state.objekti.filter((objekts) => {
        const isVisibleByToggle = state.visibleObjektuTipi.includes(
          objekts[EObjekti.TYPE]
        );

        return isVisibleByToggle;
      });
      return objektuList.filter(({ attels }) => attels);
    },
    getMapGeoJSON: (state) => () => {
      return state.objekti?.map(shelfGeoJSON);
    },
    // APMACIBAS GETTERS
    getApmacibasInfo: (state) => () => {
      return findSection(state.structure, ESectionComponents.APMACIBAS);
    },
    getApmacibas: (state) => () => {
      return state.apmacibas.filter((apmaciba) => {
        const isVisibleByToggle = state.visibleApmacibuTipi.includes(
          apmaciba[EApmacibas.TYPE]
        );

        return isVisibleByToggle;
      });
    },
    getApmacibuTipi: (state) => () => {
      return state.apmacibuTipi;
    },
    getVisibleApmacibuTipi: (state) => () => {
      return state.visibleApmacibuTipi;
    },
    // MEDIJI GETTERS
    getMedijiInfo: (state) => () => {
      return findSection(state.structure, ESectionComponents.MEDIJI);
    },
    getMediji: (state) => () => {
      const withDate = state.mediji.filter(({ datums }) => datums);
      const withoutDate = state.mediji.filter(({ datums }) => !datums);
      const sortedWithDate = withDate.sort((a: ENotikumi, b: ENotikumi) => {
        const currDate: any = new Date(a[EMediji.DATE]);
        const nextDate: any = new Date(b[EMediji.DATE]);
        return nextDate - currDate;
      });

      const sorted = [...sortedWithDate, ...withoutDate];

      const visibleMediji = sorted.filter((medijs) => {
        const isVisibleByToggle = state.visibleMedijiTipi.includes(
          medijs[EMediji.SOURCE]
        );

        return isVisibleByToggle;
      });

      return visibleMediji;
    },
    getMedijuTipi: (state) => () => {
      return state.medijiTipi;
    },
    getVisibleMedijuTipi: (state) => () => {
      return state.visibleMedijiTipi;
    },
    // NOTIKUMI GETTERS
    getNotikumiInfo: (state) => () => {
      return findSection(state.structure, ESectionComponents.NOTIKUMI);
    },
    getNotikumi: (state) => () => {
      const notikumiList = state.notikumi.filter(
        ({ nosaukums }) => !!nosaukums
      );

      const onlyUpcomming = notikumiList.filter(({ datums }) => {
        return new Date(datums) > new Date();
      });

      const sortedNotikumiList = onlyUpcomming.sort(
        (a: ENotikumi, b: ENotikumi) => {
          const currDate: any = new Date(a[ENotikumi.DATE]);
          const nextDate: any = new Date(b[ENotikumi.DATE]);

          return currDate - nextDate;
        }
      );

      return sortedNotikumiList;
    },
    // KLASTERI GETTERS
    getKlasteriInfo: (state) => () => {
      return findSection(state.structure, ESectionComponents.KLASTERIS);
    },
    getKlasteri: (state) => () => {
      return state.klasteri.filter((klasteris) => {
        return klasteris[EKlasteris.TYPE]
          ?.map((_type) => {
            return state.visibleKlasterisTipi.includes(_type);
          })
          .includes(true);
      });
    },
    getKlasteriTipi: (state) => () => {
      return state.klasterisTipi;
    },
    getVisibleKlasteri: (state) => () => {
      return state.visibleKlasterisTipi;
    },
    // PARTNERI GETTERS
    getPartneriInfo: (state) => () => {
      return findSection(state.structure, ESectionComponents.PARTNERI);
    },
    getPartneri: (state) => () => {
      return state.partneri;
    },
  },
  // Can't call async calls
  mutations: {
    setPageStructure(state) {
      if (state.structure.length === 0) {
        const collectStructureRecords = (rec) => {
          const item = {
            [EStruktura.SECTION]: rec.get(EStruktura.SECTION),
            [EStruktura.NAME]: rec.get(EStruktura.NAME),
            [EStruktura.LONG_DESC]: rec.get(EStruktura.LONG_DESC),
            [EStruktura.PUBLIC]: rec.get(EStruktura.PUBLIC),
          };
          if (item[EStruktura.PUBLIC]) {
            state.structure = [...state.structure, item];
          }
        };

        Get(EBase.STRUCTURE, collectStructureRecords);
      }
    },
    setKapecKoks(state) {
      if (state.kapecKoks.length === 0) {
        const collectKapecKoks = (rec) => {
          const item = {
            [EKapecKoks.NAME]: rec.get(EKapecKoks.NAME),
            [EKapecKoks.LONG_DESC]: rec.get(EKapecKoks.LONG_DESC),
            [EKapecKoks.IMG]: collectImages(rec.get(EKapecKoks.IMG)),
            [EKapecKoks.PUBLIC]: rec.get(EKapecKoks.PUBLIC),
          };
          if (item[EStruktura.PUBLIC]) {
            state.kapecKoks = [...state.kapecKoks, item];
          }
        };
        Get(EBase.KAPEC_KOKS, collectKapecKoks);
      }
    },
    setBuvizstradajumi(state) {
      if (state.buvizstradajumi.length === 0) {
        const collectBuvizstradajumi = (rec) => {
          const item = {
            [EBuvizstradajumi.NAME]: rec.get(EBuvizstradajumi.NAME),
            [EBuvizstradajumi.SHORT_DESC]: rec.get(EBuvizstradajumi.SHORT_DESC),
            [EBuvizstradajumi.IMG]: collectImages(
              rec.get(EBuvizstradajumi.IMG)
            ),
            [EBuvizstradajumi.PUBLIC]: rec.get(EBuvizstradajumi.PUBLIC),
          };
          if (item[EStruktura.PUBLIC]) {
            state.buvizstradajumi = [...state.buvizstradajumi, item];
          }
        };
        Get(EBase.IZSTRADAJUMI, collectBuvizstradajumi);
      }
    },
    setObjekti(state) {
      if (state.objekti.length === 0) {
        const collectObjekti = (rec) => {
          const item = {
            [EObjekti.NAME]: rec.get(EObjekti.NAME),
            [EObjekti.TYPE]: rec.get(EObjekti.TYPE),
            [EObjekti.SHOW_IN_LANDING_PAGE]: rec.get(
              EObjekti.SHOW_IN_LANDING_PAGE
            ),
            [EObjekti.SHORT_DESC]: rec.get(EObjekti.SHORT_DESC),
            [EObjekti.LONG_DESC]: rec.get(EObjekti.LONG_DESC),
            [EObjekti.URL]: rec.get(EObjekti.URL),
            [EObjekti.IMG]: collectImages(rec.get(EObjekti.IMG)),
            [EObjekti.COORDS]: [rec.get("lat"), rec.get("lng")],
            [EObjekti.PUBLIC]: rec.get(EObjekti.PUBLIC),
          };
          if (item[EObjekti.PUBLIC]) {
            if (
              item[EObjekti.TYPE] &&
              !state.objektuTipi.includes(item[EObjekti.TYPE])
            ) {
              state.objektuTipi = [...state.objektuTipi, item[EObjekti.TYPE]];

              state.visibleObjektuTipi = [
                ...state.objektuTipi,
                item[EObjekti.TYPE],
              ];
            }

            state.objekti = [...state.objekti, item];
          }
        };

        Get(EBase.OBJEKTI, collectObjekti);
      }
    },
    setApmacibas(state) {
      if (state.apmacibas.length === 0) {
        const collectApmacibas = (rec) => {
          const item = {
            [EApmacibas.NAME]: rec.get(EApmacibas.NAME),
            [EApmacibas.LONG_DESC]: rec.get(EApmacibas.LONG_DESC),
            [EApmacibas.SHORT_DESC]: rec.get(EApmacibas.SHORT_DESC),
            [EApmacibas.LOGO]: collectImages(rec.get(EApmacibas.LOGO)),
            [EApmacibas.IMG]: collectImages(rec.get(EApmacibas.IMG)),
            [EApmacibas.PAID]: rec.get(EApmacibas.PAID),
            [EApmacibas.TYPE]: rec.get(EApmacibas.TYPE),
            [EApmacibas.URL]: rec.get(EApmacibas.URL),
            [EApmacibas.PUBLIC]: rec.get(EApmacibas.PUBLIC),
          };
          if (item?.[EApmacibas.PUBLIC]) {
            if (
              item[EApmacibas.TYPE] &&
              !state.apmacibuTipi.includes(item[EApmacibas.TYPE])
            ) {
              state.apmacibuTipi = [
                ...state.apmacibuTipi,
                item[EApmacibas.TYPE],
              ];

              state.visibleApmacibuTipi = [
                ...state.apmacibuTipi,
                item[EApmacibas.TYPE],
              ];
            }

            state.apmacibas = [...state.apmacibas, item];
          }
        };
        Get(EBase.APMACIBAS, collectApmacibas);
      }
    },
    setMediji(state) {
      if (state.mediji.length === 0) {
        const collectMediji = (rec) => {
          const item = {
            [EMediji.NAME]: rec.get(EMediji.NAME),
            [EMediji.TYPE]: rec.get(EMediji.TYPE),
            [EMediji.URL]: rec.get(EMediji.URL),
            [EMediji.SOURCE]: rec.get(EMediji.SOURCE),
            [EMediji.IMG]: collectImages(rec.get(EMediji.IMG)),
            [EMediji.SHORT_DESC]: rec.get(EMediji.SHORT_DESC),
            [EMediji.DATE]: rec.get(EMediji.DATE),
            [EMediji.PUBLIC]: rec.get(EMediji.PUBLIC),
          };
          if (item[EMediji.PUBLIC]) {
            if (
              item[EMediji.SOURCE] &&
              !state.medijiTipi.includes(item[EMediji.SOURCE])
            ) {
              state.medijiTipi = [...state.medijiTipi, item[EMediji.SOURCE]];

              state.visibleMedijiTipi = [
                ...state.medijiTipi,
                item[EMediji.SOURCE],
              ];
            }

            state.mediji = [...state.mediji, item];
          }
        };
        Get(EBase.MEDIJI, collectMediji);
      }
    },
    setNotikumi(state) {
      if (state.notikumi.length === 0) {
        const collectNotikumi = (rec) => {
          const item = {
            [ENotikumi.NAME]: rec.get(ENotikumi.NAME),
            [ENotikumi.DATE]: rec.get(ENotikumi.DATE),
            [ENotikumi.ORGANIZER]: rec.get(ENotikumi.ORGANIZER),
            [ENotikumi.PLACE]: rec.get(ENotikumi.PLACE),
            [ENotikumi.SHORT_DESC]: rec.get(ENotikumi.SHORT_DESC),
            [ENotikumi.PUBLIC]: rec.get(ENotikumi.PUBLIC),
            [ENotikumi.URL]: rec.get(ENotikumi.URL),
          };
          if (item[EStruktura.PUBLIC]) {
            state.notikumi = [...state.notikumi, item];
          }
        };

        Get(EBase.NOTIKUMI, collectNotikumi);
      }
    },
    setKlasteri(state) {
      if (state.klasteri.length === 0) {
        const collectKlasteri = (rec) => {
          const item = {
            [EKlasteris.NAME]: rec.get(EKlasteris.NAME),
            [EKlasteris.IMG]: collectImages(rec.get(EKlasteris.IMG)),
            [EKlasteris.SHORT_DESC]: rec.get(EKlasteris.SHORT_DESC),
            [EKlasteris.TYPE]: rec.get(EKlasteris.TYPE),
            [EKlasteris.PUBLIC]: rec.get(EKlasteris.PUBLIC),
            [EKlasteris.URL]: rec.get(EKlasteris.URL),
          };
          if (item[EStruktura.PUBLIC]) {
            if (
              item[EKlasteris.TYPE]?.length > 0
              //
            ) {
              item[EKlasteris.TYPE].forEach((_type) => {
                if (!state.klasterisTipi.includes(_type)) {
                  state.klasterisTipi = [...state.klasterisTipi, _type];
                  state.visibleKlasterisTipi = [...state.klasterisTipi, _type];
                }
              });
            }

            state.klasteri = [...state.klasteri, item];
          }
        };
        Get(EBase.KLASTERIS, collectKlasteri);
      }
    },
    setPartneri(state) {
      if (state.partneri.length === 0) {
        const collectParneri = (rec) => {
          const item = {
            [EPartneri.NAME]: rec.get(EPartneri.NAME),
            [EPartneri.IMG]: collectImages(rec.get(EPartneri.IMG)),
            [EPartneri.PUBLIC]: rec.get(EPartneri.PUBLIC),
            [EPartneri.URL]: rec.get(EPartneri.URL),
          };
          if (item[EStruktura.PUBLIC]) {
            state.partneri = [...state.partneri, item];
          }
        };
        Get(EBase.PARTNERI, collectParneri);
      }
    },
    selectItem(state, item) {
      state.selectedItem = item;
    },
    toggleObjektuType(state, type) {
      const newTypeList = state.visibleObjektuTipi.includes(type)
        ? state.visibleObjektuTipi.filter((_type) => _type !== type)
        : [...state.visibleObjektuTipi, type];

      state.visibleObjektuTipi = [...newTypeList];
    },
    toggleApmacibuType(state, type) {
      const newTypeList = state.visibleApmacibuTipi.includes(type)
        ? state.visibleApmacibuTipi.filter((_type) => _type !== type)
        : [...state.visibleApmacibuTipi, type];

      state.visibleApmacibuTipi = [...newTypeList];
    },
    toggleMedijuType(state, type) {
      const newTypeList = state.visibleMedijiTipi.includes(type)
        ? state.visibleMedijiTipi.filter((_type) => _type !== type)
        : [...state.visibleMedijiTipi, type];

      state.visibleMedijiTipi = [...newTypeList];
    },
    toggleKlasterisType(state, type) {
      const newTypeList = state.visibleKlasterisTipi.includes(type)
        ? state.visibleKlasterisTipi.filter((_type) => _type !== type)
        : [...state.visibleKlasterisTipi, type];

      state.visibleKlasterisTipi = [...newTypeList];
    },
  },
  // Can do async call, cant change state
  actions: {},
  // Seperate state in modules
  modules: {},
});
