<template>
  <div class="notikumi_item_container">
    <div
      v-bind:key="index"
      v-for="(item, index) in this.$store.getters.getNotikumi()"
      class="notikumi_item_container color_brown_dark"
    >
      <div v-if="showMore ? true : index < 3" class="notikumi_item">
        <div class="notikumi_details">
          <label class="notikums_date">{{ item.datums }}</label>
          <label class="notikumi_vieta">{{ item.vieta }}</label>
        </div>
        <div class="notikumi_desc_box">
          <p class="notikumi_name">{{ item.nosaukums }}</p>
          <p class="notikumi_desc">{{ item.issApraksts }}</p>
        </div>
        <a class="learn_more" :href="item.saite" target="_blank">Atvērt lapu</a>
      </div>
    </div>

    <div
      v-if="this.$store.getters.getNotikumi().length > 3"
      class="show_more"
      v-on:click="toggleShowMore()"
    >
      {{ showMore ? "Rādīt mazāk" : "Rādīt vairāk" }}
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {},
  data() {
    return {
      showMore: false,
    };
  },
  methods: {
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
  },
})
export default class ApmacibasItem extends Vue {}
</script>

<style scoped>
.notikums_date,
.notikumi_vieta,
.learn_more,
.notikumi_desc {
  font-size: 1rem;
  line-height: 1.4;
  opacity: 0.8;
  font-weight: 500;
}
.notikumi_details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: left;
}
.notikumi_name {
  font-size: 18px;
  font-weight: 700;
}
.notikumi_item {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  align-items: start;
  gap: 2rem;
  padding: 2rem 0;
  border-bottom: 1px solid #36231a20;
}
.notikumi_desc_box {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.learn_more {
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  text-align: right;
}
.show_more {
  margin-top: 50px;
  margin-bottom: 20px;
  text-decoration: underline;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .notikumi_item {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .notikumi_details {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
  .learn_more {
    text-align: left;
  }
}
</style>
