// AIRTABLE ENUMS
export enum EBase {
  STRUCTURE = "00 Webpage structure",
  KAPEC_KOKS = "01 Kāpēc koks",
  OBJEKTI = "02 Objekti",
  APMACIBAS = "03 Apmācības",
  IZSTRADAJUMI = "04 Koka_būvizstrādājumi",
  KLASTERIS = "05 Koka klasteris",
  MEDIJI = "06 Mediji",
  NOTIKUMI = "07 Notikumi",
  PARTNERI = "08 Partneri",
}

// SECTION ENUMS
export enum ESectionComponents {
  KOKA_CENTRS = "KokaCentrs",
  KAPEC_KOKS = "KapecKoks",
  OBJEKTI = "Objekti",
  APMACIBAS = "Apmacibas",
  IZSTRADAJUMI = "Buvizstradajumi",
  KLASTERIS = "KokaKlaseri",
  MEDIJI = "Mediji",
  NOTIKUMI = "Notikumi",
  PARTNERI = "Partneri",
}

export enum EStruktura {
  SECTION = "sadala",
  NAME = "nosaukums",
  INDEX = "seciba",
  PUBLIC = "publisks",
  LONG_DESC = "garaisApraksts",
  STATE = "status",
  IMG = "attels",
}

export enum EKapecKoks {
  NAME = "nosaukums",
  LONG_DESC = "garaisApraksts",
  IMG = "attels",
  TYPE = "tips",
  PUBLIC = "publisks",
}

export enum EObjekti {
  NAME = "nosaukums",
  INDEX = "seciba",
  TYPE = "tips",
  SHOW_IN_LANDING_PAGE = "raditLapasAugsa",
  SHORT_DESC = "issApraksts",
  LONG_DESC = "garaisApraksts",
  IMG = "attels",
  URL = "saite",
  COORDS = "koordinates",
  PUBLIC = "publisks",
}

export enum EApmacibas {
  NAME = "nosaukums",
  SHORT_DESC = "issApraksts",
  LONG_DESC = "garaisApraksts",
  LOGO = "logo",
  IMG = "attels",
  PAID = "maksas",
  TYPE = "tips",
  URL = "saite",
  PUBLIC = "publisks",
}

export enum EBuvizstradajumi {
  NAME = "nosaukums",
  SHORT_DESC = "issApraksts",
  URL = "saite",
  IMG = "attels",
  STATE = "status",
  PUBLIC = "publisks",
}

export enum EKlasteris {
  NAME = "nosaukums",
  URL = "saite",
  IMG = "attels",
  TYPE = "tips",
  SHORT_DESC = "issApraksts",
  STATE = "status",
  PUBLIC = "publisks",
}

export enum EMediji {
  NAME = "nosaukums",
  DATE = "datums",
  URL = "saite",
  SOURCE = "avots",
  EMAIL = "epasts",
  TYPE = "tips",
  IMG = "attels",
  SHORT_DESC = "issApraksts",
  PUBLIC = "publisks",
}

export enum ENotikumi {
  NAME = "nosaukums",
  DATE = "datums",
  SHORT_DESC = "issApraksts",
  IMG = "attels",
  ORGANIZER = "organizators",
  PLACE = "vieta",
  STATE = "status",
  PUBLIC = "publisks",
  URL = "saite",
}

export enum EPartneri {
  NAME = "nosaukums",
  SOURCE = "avots",
  IMG = "attels",
  PUBLIC = "publisks",
  URL = "saite",
}
