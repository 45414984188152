<template>
  <div>
    <div
      v-bind:key="index"
      v-for="(item, index) in this.$store.getters.getApmacibas()"
    >
      <div
        class="apmacibas_item_card"
        v-if="showMoreApmacibas ? true : index < 4"
      >
        <!-- (showMoreApmacibas ? true : index < 2)  -->
        <div class="apamcibas_item_desc_box color_brown_dark">
          <img
            :src="item.attels"
            class="apmaciba_img"
            height="100%"
            width="auto"
            v-if="!!item.attels"
            loading="lazy"
          />

          <div class="apmaciba_text">
            <span class="filter_tag bg_brown_dark color_brown">{{
              item.tips
            }}</span>
            <p class="item_name">{{ item.nosaukums }}</p>
            <p v-if="item.garaisApraksts" class="item_desc">
              {{ item.garaisApraksts }}
            </p>
          </div>
          <a
            class="visit_web"
            :href="item.saite"
            v-if="!!item.saite"
            target="_blank"
          >
            Atvērt lapu
          </a>
        </div>
      </div>
    </div>

    <div
      v-if="this.$store.getters.getApmacibas()?.length > 4"
      class="show_more color_brown_dark"
      v-on:click="toggleShowMore()"
    >
      {{ showMoreApmacibas ? "Rādīt mazāk" : "Rādīt vairāk" }}
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {},
  data() {
    return {
      showMoreApmacibas: false,
    };
  },
  methods: {
    toggleShowMore() {
      this.showMoreApmacibas = !this.showMoreApmacibas;
    },
  },
})
export default class ApmacibasItem extends Vue {}
</script>

<style scoped>
.apmacibas_item_card {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 2rem;
  border-bottom: 1px solid #36231a20;
  margin-bottom: 2rem;
  box-sizing: border-box;
}

.apamcibas_item_desc_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  text-align: left;
}
.filter_tag {
  border-radius: 0.8rem;
  padding: 0.2rem 0.6rem;
  font-weight: 500;
  font-size: 0.875rem;
}

.apmaciba_img {
  border-radius: 15px;
  width: 150px;
  height: auto;
  max-height: 400px;
}
.item_name {
  font-size: 18px;
  font-weight: 700;
  margin-top: 1rem;
}
.visit_web,
.show_more {
  line-height: 1.4;
  opacity: 0.8;
  font-weight: 500;
}

.visit_web {
  width: 13ch;
}
.show_more {
  display: inline-block;
  margin-top: 4rem;
  text-decoration: underline;
  cursor: pointer;
}
.show_more:hover {
  text-decoration: none;
}

.apmaciba_text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
}

.item_desc {
  padding-top: 5px;
  font-size: 14px;
}
</style>
