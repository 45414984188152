<template>
  <div
    class="app-container"
    :class="{ overflow_hidden: !!this.$store.getters.getSelectedItem() }"
  >
    <!-- Maps all sections from airtable -->
    <component
      v-bind:key="sadala"
      v-for="{ sadala } in this.$store.getters.getPageStructure()"
      :is="getSection(sadala)"
    />
    <Footer v-if="!!this.$store.getters.getPageStructure().length" />
    <Dialog v-if="this.$store.getters.getSelectedItem()" />
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { ESectionComponents } from "../assets/enums";
import Header from "./../components/Header.vue";
import KapecKoks from "./../components/KapecKoks.vue";
import Buvizstradajumi from "./../components/Buvizstradajumi.vue";
import Objekti from "./../components/Objekti.vue";
import Apmacibas from "./../components/Apmacibas.vue";
import Mediji from "./../components/Mediji.vue";
import Notikumi from "./../components/Notikumi.vue";
import Klasteri from "./../components/Klasteri.vue";
import Partneri from "./../components/Partneri.vue";
import Footer from "./../components/Footer.vue";
import Dialog from "./../components/Dialog.vue";

@Options({
  components: {
    Header,
    KapecKoks,
    Buvizstradajumi,
    Apmacibas,
    Mediji,
    Notikumi,
    Klasteri,
    Partneri,
    Footer,
    Dialog,
  },
  methods: {
    getSection(name) {
      switch (name) {
        case ESectionComponents.KOKA_CENTRS:
          return Header;
        case ESectionComponents.KAPEC_KOKS:
          return KapecKoks;
        case ESectionComponents.IZSTRADAJUMI:
          return Buvizstradajumi;
        case ESectionComponents.OBJEKTI:
          return Objekti;
        case ESectionComponents.APMACIBAS:
          return Apmacibas;
        case ESectionComponents.MEDIJI:
          return Mediji;
        case ESectionComponents.NOTIKUMI:
          return Notikumi;
        case ESectionComponents.KLASTERIS:
          return Klasteri;
        case ESectionComponents.PARTNERI:
          return Partneri;
      }
    },
    loadStructure() {
      this.$store.commit("setPageStructure");
      this.$store.commit("setKapecKoks");
      this.$store.commit("setBuvizstradajumi");
      this.$store.commit("setObjekti");
      this.$store.commit("setApmacibas");
      this.$store.commit("setMediji");
      this.$store.commit("setNotikumi");
      this.$store.commit("setKlasteri");
      this.$store.commit("setPartneri");
    },
  },
  beforeMount() {
    this.loadStructure();
  },
  mounted() {},
})
export default class HomeView extends Vue {}
</script>

<style>
.app-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  height: 100vh;
}

.no_scroll {
  overflow-y: hidden;
}
</style>
