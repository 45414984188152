<template>
  <div class="mediji_item_wrapper">
    <div
      v-bind:key="index"
      v-for="(item, index) in this.$store.getters.getMediji()"
    >
      <div
        v-if="showMore ? true : index < 6"
        class="mediji_item color_brown_light"
        :style="{ backgroundColor: getColor() }"
      >
        <img
          class="medija_image"
          v-if="item.attels"
          :src="item.attels[0]"
          loading="lazy"
        />

        <div class="mediji_item_desc_box">
          <p class="medija_source">{{ item.avots }}</p>
          <p class="medija_name">{{ item.nosaukums }}</p>
          <p class="medija_desc">{{ item.issApraksts }}</p>
        </div>
        <div class="mediji_card_footer">
          <a
            :href="item.saite"
            v-if="item.saite"
            target="_blank"
            class="mediji_hyperlink"
          >
            {{ getTypeLabel(item.tips, item.saite) }}
          </a>
          <p class="medija_date" v-if="item.datums">
            {{ item.datums }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="this.$store.getters.getMediji().length > 6"
    class="show_more color_brown_dark"
    v-on:click="toggleShowMore()"
  >
    {{ showMore ? "Rādīt mazāk" : "Rādīt vairāk" }}
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {},
  data() {
    return {
      showMore: false,
    };
  },
  methods: {
    getColor(index) {
      const randomColor = Math.floor(Math.random() * 5);
      switch (randomColor) {
        case 0:
          return "#503434";
        case 1:
          return "#24413C";
        case 2:
          return "#423656";
        case 3:
          return "#423656";
        case 4:
          return "#503434";
        case 5:
          return "#44332C";
      }
    },
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
    getTypeLabel(type, url) {
      switch (type) {
        case "PDF": {
          return "Lejupielādēt PDF";
        }
        case "URL": {
          if (url.includes("youtube")) {
            return "Skatīt interviju";
          } else {
            return "Atvērt lapu";
          }
        }
      }
    },
  },
})
export default class ApmacibasItem extends Vue {}
</script>

<style>
.mediji_item_wrapper {
  display: inline-grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  justify-items: stretch;
  width: 100%;
}

.mediji_item_desc_box {
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}
.mediji_item {
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  text-align: left;
  padding: 0.5rem;
  height: 100%;
  box-sizing: border-box;
}
.medija_name {
  font-weight: 700;
  font-size: 18px;
  margin-top: 0.5rem;
}
.medija_source,
.medija_date {
  font-size: 0.875rem;
  font-weight: 600;
  opacity: 0.8;
}
.medija_desc {
  font-size: 0.875rem;
  margin-top: 1rem;
  opacity: 0.8;
  font-weight: 500;
}
.mediji_hyperlink {
  text-decoration: underline;
  font-size: 0.875rem;
  opacity: 0.8;
  font-weight: 500;
}

.medija_image {
  width: 100%;
  border-radius: 1rem;
  max-height: 260px;
}
.mediji_card_footer {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  font-size: 0.875rem;
  padding: 0 1.5rem 1.5rem 1.5rem;
}
@media screen and (max-width: 1024px) {
  .mediji_item_wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 560px) {
  .mediji_item_wrapper {
    grid-template-columns: 1fr;
  }
}
</style>
