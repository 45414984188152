<template>
  <div class="wrapper bg_brown" :id="id">
    <div class="sub_wrapper klasteri_container color_brown_dark">
      <h2>{{ nosaukums }}</h2>
      <p class="desc">{{ apraksts }}</p>

      <div
        class="filter_row"
        v-if="this.$store.getters.getKlasteriTipi()?.length"
      >
        <span
          class="filter_tag bg_brown_dark color_brown"
          v-for="type in this.$store.getters.getKlasteriTipi()"
          v-bind:key="type"
          :class="{
            unselected: !this.$store.getters
              .getVisibleKlasteri()
              .includes(type),
          }"
          v-on:click="() => toggleType(type)"
        >
          {{ type }}
        </span>
      </div>

      <div class="klasteri_box">
        <a
          v-for="(item, index) in this.$store.getters.getKlasteri()"
          v-bind:key="index"
          class="klasteri_item"
          :href="item.saite"
          target="_blank"
        >
          <div
            class="klasteri_img"
            :style="{ backgroundImage: `url(${item.attels?.[0]})` }"
          />
          <p class="klasteri_title">{{ item.nosaukums }}</p>
          <!-- <p class="klasteri_desc">{{ item.issApraksts }}</p> -->
          <span class="klasteri_item_box">
            <p
              v-for="tips in item.tips"
              v-bind:key="tips"
              class="bg_brown_dark color_brown klasteri_tips"
            >
              {{ tips }}
            </p>
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { EBase } from "../assets/enums";

@Options({
  components: {},
  props: {},
  data() {
    return {
      id: this.$store.getters.getKlasteriInfo()?.nosaukums,
      nosaukums: this.$store.getters.getKlasteriInfo()?.nosaukums,
      apraksts: this.$store.getters.getKlasteriInfo()?.garaisApraksts,
    };
  },
  methods: {
    toggleType(type) {
      this.$store.commit("toggleKlasterisType", type);
    },
  },
})
export default class Klasteri extends Vue {}
</script>

<style scoped>
.klasteri_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.klasteri_box {
  display: inline-grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  justify-items: stretch;
  align-items: start;
  width: 100%;
}

.klasteri_item {
  display: flex;
  flex-direction: column;
  border-radius: 1.5rem;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-decoration: none;
}
.klasteri_tips {
  border-radius: 0.8rem;
  padding: 0.2rem 0.6rem;
  font-weight: 500;
  font-size: 0.875rem;
  margin: 0.2rem;
}
.klasteri_img {
  background-color: white;
  border-radius: 1.5rem;
  width: 160px;
  height: 120px;
  margin-bottom: 1rem;
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center;
}
.klasteri_title {
  font-size: 18px;
  font-weight: 700;
}
.klasteri_desc {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  opacity: 0.8;
  font-weight: 500;
}
.klasteri_item_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.klasteri_item:hover {
  position: cursor;
  background-color: #cfac88;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1024px) {
  .klasteri_box {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 640px) {
  .klasteri_box {
    grid-template-columns: 1fr;
  }
}
</style>
