<template>
  <div class="wrapper bg_brown_light" :id="id">
    <div class="sub_wrapper mediji_container color_brown_dark">
      <h2>{{ nosaukums }}</h2>
      <p class="desc">{{ apraksts }}</p>
      <div
        class="filter_row"
        v-if="this.$store.getters.getMedijuTipi()?.length"
      >
        <span
          class="filter_tag bg_brown_dark color_brown"
          v-for="type in this.$store.getters.getMedijuTipi()"
          v-bind:key="type"
          :class="{
            unselected: !this.$store.getters
              .getVisibleMedijuTipi()
              .includes(type),
          }"
          v-on:click="() => toggleType(type)"
        >
          {{ type }}
        </span>
      </div>
      <Item />
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { EBase } from "../assets/enums";
import Item from "./MedijiItems.vue";

@Options({
  components: { Item },
  props: {},
  data() {
    return {
      id: this.$store.getters.getMedijiInfo()?.nosaukums,
      nosaukums: this.$store.getters.getMedijiInfo()?.nosaukums,
      apraksts: this.$store.getters.getMedijiInfo()?.garaisApraksts,
    };
  },
  methods: {
    toggleType(type) {
      this.$store.commit("toggleMedijuType", type);
    },
  },
})
export default class Mediji extends Vue {}
</script>

<style scoped>
.wrapper {
  border-top: 1px solid #36231a20;
}
.mediji_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
